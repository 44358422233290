<template>
  <div class="pension-simulator-menu-container">
    <b-row class="submenu-container dark chart-menu-container justify-content-center " no-gutters>
      <b-col md="auto">
        <b-link tab="amount" :class="{disabled: activeTab == 'amount'}"  @click="onTabClick">
          {{ $t(translationPath + 'tab_amount_title') }}
        </b-link>
        <span class="mx-3 divider">|</span>
      </b-col>
      <b-col md="auto">
        <b-link tab="withdrawal" :class="{disabled: activeTab == 'withdrawal'}"  @click="onTabClick">
          {{ $t(translationPath + 'tab_withdrawal_title') }}
        </b-link>
        <span class="mx-3 divider">|</span>
      </b-col>
      <b-col md="auto">
        <b-link tab="withdrawal-per-month" :class="{disabled: activeTab == 'withdrawal-per-month'}"  @click="onTabClick">
          {{ $t(translationPath + 'tab_widthdrawal_per_month_title') }}
        </b-link>
        <span class="mx-3 divider">|</span>
      </b-col>
      <b-col md="auto">
        <b-link tab="allocation" :class="{disabled: activeTab == 'allocation'}"  @click="onTabClick">
          {{ $t(translationPath + 'tab_allocation_title') }}
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'PensionSimulatorSubmenu',
  props: {
    active: {
      type: String,
      default: 'amount'
    }
  },
  data () {
    return {
      activeTab: null,
      translationPath: 'reports.simulators.pension.'
    }
  },
  created () {
    this.activeTab = this.active
  },
  methods: {
    onTabClick (event) {
      this.activeTab = event.target.attributes.tab.value
      this.$emit('tab-switched', event.target.attributes.tab.value)
    },
    changeTab (tab) {
      this.activeTab = tab
      this.$emit('tab-switched', tab)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/submenu.scss';
  .site-divider{
    margin-top:10px;
    margin-bottom:20px;
    @media screen and (max-width: 767.98px) {
      margin-top:10px;
      margin-bottom:20px;
    }
  }
  .pension-simulator-menu-container {
    margin-top: 0px;
  }
  .pension-simulator-submenu-container {
    padding-top: 4px;
    padding-bottom: 24px;

    .col-md-auto{
      padding-right:20px;
    }
  }
</style>
