<template>
  <div class="pension-simulator-wrapper">
    <b-container class="main-gradient light" fluid>
      <b-row class="main-gradient-header-wrapper" no-gutters>
        <b-col no-gutters>
          <b-breadcrumb :items="breadcrumbItems" class="breadcrumb-light"></b-breadcrumb>
          <h1 class="text-left">{{ $t(translationPath + 'report_title') }}</h1>

          <pension-simulator-submenu ref="Tabmenu" class="overview" :active="activeTab" @tab-switched="onTabSwitched"></pension-simulator-submenu>
        </b-col>
      </b-row>

      <div class="tab-wrapper">
        <tab-amount v-if="activeTab === 'amount'" :apiData="apiData"></tab-amount>
        <tab-withdrawal v-else-if="activeTab === 'withdrawal'" :apiData="apiData"></tab-withdrawal>
        <tab-withdrawal-per-month v-else-if="activeTab === 'withdrawal-per-month'" :apiData="apiData"></tab-withdrawal-per-month>
        <tab-allocation v-else-if="activeTab === 'allocation'" :apiData="apiData"></tab-allocation>
      </div>
    </b-container>

    <b-container class="px-0">
      <b-row class="main-content-wrapper pb-0" no-gutters>
        <div class="card-container px-0">
            <b-card class="main-gradient-content-card borderlight mx-xl-auto mb-4 mt-4 pt-3 pb-0 px-0">
              <template v-slot:header>
                <b-row>
                  <b-col lg="6">
                    <h2>{{ $t(translationPath + 'report_title') }}</h2>
                  </b-col>
                  <b-col lg="6" class="text-lg-right">
                    <i18n :path="translationPath + 'heading_amount'" tag="h2">
                      <template slot="amount">{{ tableItems !== null ? tableItems[tableItems.length - 1].amount : 0 }}</template>
                      <template slot="year">{{ params.endYear }}</template>
                    </i18n>
                  </b-col>
                </b-row>
              </template>

              <p class="mb-4">{{ $t(translationPath + 'report_description') }}</p>

              <div>
                <b-tabs class="custom-spirecta-tab" content-class="mt-3" fill>
                  <b-tab :title="$t(translationPath + 'subtab_current_situation')" active>
                    <!-- START Current situation -->
                    <p class="mb-4">{{ $t(translationPath + 'description_current_situation') }}</p>
                    <b-col cols="12" class="px-0">
                      <b-form>
                        <b-row class="px-1">
                          <b-col cols="12" md="6" lg="3" xl="2">
                            <b-form-group
                              class="mb-0"
                              label-for="start_year"
                              label-cols="12"
                              label-align="left"
                              required
                              label-class="pr-4 text-black FormLabels"
                            >
                              <template slot="label">{{ $t(translationPath + 'field_period_label') }}</template>
                              <b-row no-gutters>
                                <b-col cols="5">
                                  <number-input
                                    input-id="start_year"
                                    v-model="params.startYear"
                                    :min="2000"
                                    :max="9999"
                                    :placeholder="(new Date().getFullYear()).toString()"
                                    @input="$v.params.startYear.$touch()"
                                  />
                                </b-col>
                                <b-col cols="2" class="text-center mt-2 pt-1">
                                  <span> - </span>
                                </b-col>
                                <b-col cols="5">
                                  <number-input
                                    input-id="end_year"
                                    v-model="params.endYear"
                                    :min="2000"
                                    :max="9999"
                                    :placeholder="(new Date().getFullYear() + 50).toString()"
                                    @input="$v.params.endYear.$touch()"
                                  />
                                </b-col>
                              </b-row>
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" md="6" lg="2" xl="1">
                            <b-form-group
                              label-class="FormLabels"
                              label-for="your_age_today"
                              label-cols="12"
                              label-align="left"
                            >
                              <template slot="label">{{ $t(translationPath + 'field_your_age_today') }}</template>
                              <number-input
                                input-id="your_age_today"
                                v-model="params.yourAgeToday"
                                :min="10"
                                :max="122"
                                placeholder="65"
                                @input="$v.params.yourAgeToday.$touch()"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" md="6" lg="3" xl="5">
                            <b-form-group
                              label-class="FormLabels"
                              label-for="starting_amount"
                              label-cols="12"
                              label-align="left"
                            >
                              <template slot="label">{{ $t(translationPath + 'field_starting_amount_label') }}</template>
                              <b-col sm="12" md="5" xl="3" class="float-left mb-2 pl-0 pr-0">
                                <currency-input
                                  ref="CurrencyInputStartingAmount"
                                  id="starting_amount"
                                  v-model="params.startingAmount"
                                  :precision="0"
                                  :input-class="$v.params.startingAmount.$error ? 'is-invalid' : ''"
                                  :state="$v.params.startingAmount.$dirty ? !$v.params.startingAmount.$error : null"
                                  @input="$v.params.startingAmount.$touch()"
                                />
                              </b-col>
                              <b-col sm="12" md="7" xl="9" class="float-left pl-0 pb-2">
                                <b-form-input
                                  id="starting_amount_slider"
                                  class="slider ml-2"
                                  type="range"
                                  :min="0"
                                  :max="2000000"
                                  :step="5000"
                                  v-model="params.startingAmount"
                                ></b-form-input>
                              </b-col>
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" md="6" lg="4" xl="4">
                            <b-form-group
                              label-class="FormLabels"
                              label-for="amount_per_month"
                              label-cols="12"
                              label-align="left"
                            >
                              <template slot="label">{{ $t(translationPath + 'field_amount_per_month_label') }}</template>
                              <b-col sm="12" md="5" xl="3" class="float-left mb-2 pl-0 pr-0">
                                <currency-input
                                  ref="CurrencyInputAmountPerMonth"
                                  id="amount_per_month"
                                  v-model="params.amountPerMonth"
                                  :precision="0"
                                  :input-class="$v.params.amountPerMonth.$error ? 'is-invalid' : ''"
                                  :state="$v.params.amountPerMonth.$dirty ? !$v.params.amountPerMonth.$error : null"
                                  @input="$v.params.amountPerMonth.$touch()"
                                />
                              </b-col>
                              <b-col sm="12" md="7" xl="9" class="float-left pl-0 pb-2">
                                <b-form-input
                                  id="amount_per_month_slider"
                                  class="slider ml-2"
                                  type="range"
                                  :min="0"
                                  :max="20000"
                                  :step="100"
                                  v-model="params.amountPerMonth"
                                ></b-form-input>
                              </b-col>
                            </b-form-group>
                          </b-col>
                        </b-row>

                      </b-form>
                    </b-col>
                    <!-- END current situation -->
                  </b-tab>
                  <b-tab :title="$t(translationPath + 'subtab_desired_situation')">
                  <!-- START Desired position -->
                    <p>{{$t(translationPath + 'description_desired_situation')}}</p>
                    <b-row class="px-1">
                      <b-col cols="12" md="6" lg="6" xl="2" class="float-left numericField">
                        <b-form-group
                          label-class="FormLabels"
                          label-for="pension_withdrawal_age"
                          label-cols="12"
                          label-align="left"
                        >
                          <template slot="label">{{ $t(translationPath + 'field_pension_withdrawal_age_label') }}</template>
                          <b-form-input
                            id="pension_withdrawal_age"
                            type="number"
                            min="10"
                            max="100"
                            placeholder="65"
                            v-model="params.pensionWithdrawalAge"
                            :state="$v.params.pensionWithdrawalAge.$dirty ? !$v.params.pensionWithdrawalAge.$error : null"
                            @input="$v.params.pensionWithdrawalAge.$touch()"
                          ></b-form-input>
                          <div class="inYearSection">
                            <i18n :path="translationPath + 'in_year'" tag="span">
                              <template slot="year">{{ pensionYear }}</template>
                            </i18n>
                          </div>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="6" lg="6" xl="6">
                        <b-form-group
                          label-class="FormLabels"
                          label-for="expense_per_month"
                          label-cols="12"
                          label-align="left"
                        >
                          <template slot="label">{{ $t(translationPath + 'field_expenses_per_month') }}</template>
                          <b-col sm="12" md="6" xl="4" class="float-left mb-2 pl-0 pr-0">
                            <currency-input
                              ref="CurrencyInputExpensePerMonth"
                              id="expense_per_month"
                              v-model="params.expensePerMonth"
                              :precision="0"
                              :class="$v.params.expensePerMonth.$error ? 'is-invalid' : ''"
                              :state="$v.params.expensePerMonth.$dirty ? !$v.params.expensePerMonth.$error : null"
                              @input="$v.params.expensePerMonth.$touch()"
                            />
                          </b-col>
                          <b-col sm="12" md="6" xl="8" class="float-left pl-0 pb-2">
                            <b-form-input
                              id="expense_per_month_slider"
                              class="slider ml-2"
                              type="range"
                              :min="0"
                              :max="100000"
                              :step="500"
                              v-model="params.expensePerMonth"
                            ></b-form-input>
                          </b-col>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="6" lg="6" xl="4">
                        <b-form-group
                          label-class="FormLabels"
                          label-for="withdrawal_percentage"
                          label-cols="12"
                          label-align="left"
                        >
                          <template slot="label">{{ $t(translationPath + 'field_withdrawal_percentage_label') }}</template>
                          <percentage-input
                            inputId="withdrawal_percentage"
                            v-model="params.withdrawalPercentage"
                            :min="1"
                            :max="10"
                            slider
                            sliderPosition="right"
                            @input="$v.params.withdrawalPercentage.$touch()"
                          ></percentage-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <!-- END Desired position -->
                  </b-tab>
                  <b-tab :title="$t(translationPath + 'subtab_assumptions')">
                    <!-- START Assumptions -->
                    <p class="mb-4">{{ $t(translationPath + 'description_assumptions_situation') }}</p>
                    <b-row class="px-1">
                      <b-col cols="6" md="6" lg="4" xl="2" class="float-left numericField">
                        <b-form-group
                          label-class="FormLabels"
                          label-for="stock_market_avg_return"
                          label-cols="12"
                          label-align="left"
                        >
                          <template slot="label">{{ $t(translationPath + 'field_stock_market_avg_return_label') }}</template>
                          <percentage-input
                            inputId="stock_market_avg_return"
                            v-model="params.stockMarketAvgReturn"
                            @input="$v.params.stockMarketAvgReturn.$touch()"
                          ></percentage-input>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6" md="6" lg="4" xl="2" class="float-left numericField">
                        <b-form-group
                          label-class="FormLabels"
                          label-for="bond_market_avg_return"
                          label-cols="12"
                          label-align="left"
                        >
                          <template slot="label">{{ $t(translationPath + 'field_bond_market_avg_return_label') }}</template>
                          <percentage-input
                            inputId="bond_market_avg_return"
                            v-model="params.bondMarketAvgReturn"
                            @input="$v.params.bondMarketAvgReturn.$touch()"
                          ></percentage-input>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6" md="6" lg="4" xl="2" class="float-left numericField">
                        <b-form-group
                          label-class="FormLabels"
                          label-for="bond_max_percentage"
                          label-cols="12"
                          label-align="left"
                        >
                          <template slot="label">{{ $t(translationPath + 'field_bond_max_percentage_label') }}</template>
                          <percentage-input
                            inputId="bond_max_percentage"
                            v-model="params.bondMaxPercentage"
                            @input="$v.params.bondMaxPercentage.$touch()"
                          ></percentage-input>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6" md="6" lg="4" xl="2" class="float-left numericField">
                        <b-form-group
                          label-class="FormLabels"
                          label-for="start_decrease_equity_age"
                          label-cols="12"
                          label-align="left"
                        >
                          <template slot="label">{{ $t(translationPath + 'field_start_decrease_equity_age_label') }}</template>
                          <b-form-input
                            id="start_decrease_equity_age"
                            type="number"
                            min="10"
                            max="100"
                            placeholder="55"
                            v-model="params.startDecreaseEquityAge"
                            :state="$v.params.startDecreaseEquityAge.$dirty ? !$v.params.startDecreaseEquityAge.$error : null"
                            @input="$v.params.startDecreaseEquityAge.$touch()"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6" md="6" lg="4" xl="2" class="float-left numericField">
                        <b-form-group
                          label-class="FormLabels"
                          label-for="amount_per_month"
                          label-cols="12"
                          label-align="left"
                        >
                          <template slot="label">{{ $t(translationPath + 'field_annual_increase_bon_percentage_label') }}</template>
                          <percentage-input
                            inputId="annual_increase_bond_percentage"
                            v-model="params.annualIncreaseBondPercentage"
                            @input="$v.params.annualIncreaseBondPercentage.$touch()"
                          ></percentage-input>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6" md="6" lg="4" xl="2" class="float-left numericField">
                        <b-form-group
                          label-class="FormLabels"
                          label-for="inflation"
                          label-cols="12"
                          label-align="left"
                        >
                          <template slot="label">{{ $t(translationPath + 'field_inflation_label') }}</template>
                          <percentage-input
                            inputId="inflation"
                            v-model="params.inflation"
                            @input="$v.params.inflation.$touch()"
                          ></percentage-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <!-- END Assumptions -->
                  </b-tab>
                </b-tabs>
              </div>

            </b-card>
        </div>
      </b-row>

      <b-row class="main-content-wrapper pb-0" no-gutters>
        <div class="card-container px-0">
            <b-card class="main-gradient-content-card borderlight mx-xl-auto mb-0 mt-4 px-0 pt-3">
              <template v-slot:header>
                <b-row>
                  <b-col lg="6">
                    <h2>{{$t(translationPath + 'table_title')}}</h2>
                  </b-col>
                </b-row>
              </template>

              <p>{{$t(translationPath + 'table_description')}}</p>
              <b-table class="spirecta-simple-table mb-0" show-empty hover responsive striped
                stacked="md"
                :items="tableItems"
                :fields="fieldValues"
                :filter="filter"
                :busy="busyState"
                @filtered="onTableFiltered"
              >
                <!--loader-->
                <template v-slot:table-busy>
                  <loader/>
                </template>
                <!--loader ends here-->
                <template slot="top-row">
                    <td colspan="10">
                      <div class="d-flex align-items-center">
                        <i class="fa fa-search"></i>
                        <b-form-input v-model="filter" size="sm" :placeholder="$t(translationPath + 'table_filter_placeholder')" />
                      </div>
                    </td>
                </template>
              </b-table>
            </b-card>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import { required, integer, between, minValue } from 'vuelidate/lib/validators'
import formatAmount from '@/assets/filters/formatAmount'
import _ from 'lodash'
import PensionSimulatorSubmenu from './PensionSimulatorSubmenu'
import TabAmount from './TabAmount'
import TabWithdrawal from './TabWithdrawal'
import TabWithdrawalPerMonth from './TabWithdrawalPerMonth'
import TabAllocation from './TabAllocation'
import Loader from '@/components/common/Loader'
import titleMixins from '@/mixins/title'
import PercentageInput from '@/components/common/PercentageInput'
import CurrencyInput from '@/components/common/CurrencyInput'
import useComponentCurrencyInputMixin from '@/mixins/useComponentCurrencyInput'
import NumberInput from '@/components/common/NumberInput'

const isEndYearGreater = getter => function () {
  return parseInt(this.params.endYear) > parseInt(this.params.startYear)
}

export default {
  name: 'PensionSimulator',
  mixins: [titleMixins, useComponentCurrencyInputMixin],
  components: {
    PensionSimulatorSubmenu,
    TabAmount,
    TabWithdrawal,
    TabWithdrawalPerMonth,
    TabAllocation,
    Loader,
    PercentageInput,
    CurrencyInput,
    NumberInput
  },
  filters: {
    formatAmount
  },
  validations: {
    params: {
      startYear: {
        required,
        integer,
        between: between(1900, 2200)
      },
      endYear: {
        required,
        integer,
        between: between(1901, 2201),
        isGreaterThanStartYear: isEndYearGreater()
      },
      amountPerMonth: {
        required,
        minValue: minValue(0)
      },
      expensePerMonth: {
        required,
        minValue: minValue(0)
      },
      startingAmount: {
        required,
        minValue: minValue(0)
      },
      withdrawalPercentage: {
        required,
        integer,
        between: between(1, 100)
      },
      yourAgeToday: {
        required,
        integer,
        between: between(1, 100)
      },
      pensionWithdrawalAge: {
        required,
        integer,
        between: between(1, 100)
      },
      startDecreaseEquityAge: {
        required,
        integer,
        between: between(1, 100)
      },
      bondMaxPercentage: {
        required,
        integer,
        between: between(0, 100)
      },
      annualIncreaseBondPercentage: {
        required,
        integer,
        between: between(0, 100)
      },
      bondMarketAvgReturn: {
        required,
        integer,
        between: between(0, 10)
      },
      stockMarketAvgReturn: {
        required,
        integer,
        between: between(0, 50)
      },
      inflation: {
        required,
        integer,
        between: between(0, 10)
      }
    },
    form: [
      'params.startYear',
      'params.endYear',
      'params.amountPerMonth',
      'params.expensePerMonth',
      'params.startingAmount',
      'params.withdrawalPercentage',
      'params.pensionWithdrawalAge',
      'params.yourAgeToday',
      'params.startDecreaseEquityAge',
      'params.bondMaxPercentage',
      'params.annualIncreaseBondPercentage',
      'params.bondMarketAvgReturn',
      'params.stockMarketAvgReturn',
      'params.inflation'
    ]
  },
  data () {
    return {
      params: {
        startYear: null,
        endYear: null,
        amountPerMonth: 3000,
        expensePerMonth: 10000,
        startingAmount: 100000,
        withdrawalPercentage: 4,
        pensionWithdrawalAge: 65,
        yourAgeToday: null,
        startDecreaseEquityAge: 55,
        bondMaxPercentage: 70,
        annualIncreaseBondPercentage: 2,
        stockMarketAvgReturn: 7,
        bondMarketAvgReturn: 1,
        inflation: 2
      },
      activeTab: 'amount',
      apiData: null,
      busyState: true,
      translationPath: 'reports.simulators.pension.',
      filter: ''
    }
  },
  computed: {
    ...mapState('user', ['currentCOA', 'currentUser']),
    breadcrumbItems () {
      return [
        { text: this.$t('reports._common.reports_and_insights'), to: '/reports/performance' },
        { text: this.$t('reports._common.simulator_reports'), to: '/reports/simulators' },
        { text: '', active: true }
      ]
    },
    pensionYear () {
      const currentYear = this.$moment().format('YYYY')
      const currentAge = this.params.yourAgeToday || 0
      const pensionAge = this.params.pensionWithdrawalAge || 0
      const diff = Math.abs(pensionAge - currentAge)
      return parseInt(currentYear) + parseInt(diff)
    },
    fieldValues () {
      return [
        { key: 'year', label: this.$t(this.translationPath + 'th_year') },
        { key: 'age', label: this.$t(this.translationPath + 'th_age') },
        { key: 'equity_percentage', label: this.$t(this.translationPath + 'th_stock') },
        { key: 'bond_percentage', label: this.$t(this.translationPath + 'th_bond') },
        { key: 'amount', label: this.$t(this.translationPath + 'th_amount') },
        { key: 'monthly_saving', label: this.$t(this.translationPath + 'th_monthly_saving') },
        { key: 'expenses_per_year', label: this.$t(this.translationPath + 'th_expenses_per_year') },
        { key: 'withdrawal', label: this.$t(this.translationPath + 'th_withdrawal') },
        { key: 'expenses_per_month', label: this.$t(this.translationPath + 'th_expenses_per_month') },
        { key: 'withdrawal_per_month', label: this.$t(this.translationPath + 'th_withdrawal_per_month') }
      ]
    },
    tableItems () {
      const items = _.cloneDeep(this.apiData)
      const propsToFormatAsAmount = ['amount', 'withdrawal', 'monthly_saving', 'withdrawal_per_month', 'expenses_per_year', 'expenses_per_month']
      const propsToFormatAsPercentage = ['bond_percentage', 'equity_percentage']
      for (var prop in items) {
        items[prop].withdrawal_per_month = Math.round(items[prop].withdrawal / 12)
        for (var field in propsToFormatAsAmount) {
          items[prop][propsToFormatAsAmount[field]] = this.currentCOA.locale ? formatAmount(
            items[prop][propsToFormatAsAmount[field]],
            this.currentCOA.locale,
            this.currentCOA.currency,
            this.currentCOA.currency_iso,
            true,
            0
          ) : items[prop][propsToFormatAsAmount[field]]
        }
        for (field in propsToFormatAsPercentage) {
          items[prop][propsToFormatAsPercentage[field]] = (this.currentCOA.locale ? formatAmount(
            items[prop][propsToFormatAsPercentage[field]] * 100,
            this.currentCOA.locale,
            this.currentCOA.currency,
            this.currentCOA.currency_iso,
            false,
            0
          ) : items[prop][propsToFormatAsPercentage[field]] * 100) + ' %'
        }
      }
      return items
    },
    titleMeta () {
      return this.$t(this.translationPath + 'auto_calculation')
    }
  },
  methods: {
    async loadData () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.busyState = false
        return false
      }
      this.busyState = true
      const data = {
        start_year: parseInt(this.params.startYear),
        end_year: parseInt(this.params.endYear),
        amount_per_month: this.params.amountPerMonth,
        starting_amount: this.params.startingAmount,
        withdrawal_percentage: this.params.withdrawalPercentage,
        pension_withdrawal_age: this.params.pensionWithdrawalAge,
        your_age_today: this.params.yourAgeToday,
        start_decrease_equity_age: this.params.startDecreaseEquityAge,
        bond_max_percentage: this.params.bondMaxPercentage,
        annual_increase_bond_percentage: this.params.annualIncreaseBondPercentage,
        stock_market_avg_return: this.params.stockMarketAvgReturn,
        bond_market_avg_return: this.params.bondMarketAvgReturn,
        inflation: this.params.inflation,
        expense_per_month: this.params.expensePerMonth
      }

      axios.post(process.env.VUE_APP_ROOT_API + '/financial/planner/pension', data)
        .then((response) => {
          this.apiData = response.data.data
          this.busyState = false
        })
        .catch((error) => {
          console.error(error)
          this.busyState = false
        })
    },
    onTabSwitched (newtab) {
      this.activeTab = newtab
      if (newtab !== this.$route.params.tab) {
        this.$router.push({ params: { tab: newtab } })
      }
    },
    changeTab (tab) {
      if (['amount', 'withdrawal', 'withdrawal-per-month', 'allocation'].indexOf(this.$route.params.tab) !== -1) {
        this.$refs.Tabmenu.changeTab(tab)
      }
    },
    onTableFiltered (items) {
      return false
    }
  },
  watch: {
    currentUser: {
      deep: true,
      handler () {
        this.params.startYear = this.$moment().format('YYYY')
        if (!this.params.endYear && this.currentUser.birth_year) {
          this.params.endYear = parseInt(this.currentUser.birth_year) + 100
        } else {
          this.params.endYear = parseInt(this.params.startYear) + 50
        }
      }
    },
    params: {
      deep: true,
      handler () {
        this.rerenderCurrencyInputs()
        this.debounceLoadData()
      }
    },
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    },
    '$route.params.tab' () {
      this.changeTab(this.$route.params.tab)
    }
  },
  created () {
    this.debounceLoadData = _.debounce(this.loadData, 600)
    this.loadData()
  },
  mounted () {
    this.changeTab(this.$route.params.tab)
    const currentUserYear = (this.currentUser && Object.prototype.hasOwnProperty.call(this.currentUser, 'birth_year') && this.currentUser.birth_year) ? this.currentUser.birth_year : null

    this.params.startYear = this.$moment().format('YYYY')
    if (currentUserYear) {
      const currentYear = this.$moment().format('YYYY')
      const diff = currentYear - currentUserYear
      this.params.yourAgeToday = diff > 0 ? diff : 40
      this.params.endYear = parseInt(this.currentUser.birth_year) + 100
    } else {
      this.params.yourAgeToday = 40
      this.params.endYear = this.params.startYear + 60
    }
  }
}
</script>

<style lang="scss">
.pension-simulator-wrapper {

  .custom-spirecta-tab{
    li.nav-item a{
      border-bottom:1px solid #dee2e6;
    }

    .nav-tabs li a.active{
      background-color: white;
      border-bottom:1px solid white;
    }
  }

  .FormLabels {
    padding-left: 0px;
    padding-right: 0px;
    font-size: 13px;
  }
  .main-content-wrapper {
    padding-top:0;
  }

  .tablePensionWrapper{
    padding-left:35px;
    padding-right:35px;
    padding-top:0px;
    margin-top:0px;
    margin-bottom:50px;
  }
  .tablePensions {
    background: #fff;
    padding: 35px 35px;

  }
  .numericField {
    input {
      @media screen and (min-width:1440px) and (max-width:2500px) {
        max-width: 65px;
      }
    }
  }
  #pension_withdrawal_age {
    @media screen and (max-width:768px) {
      max-width: 85%;
    }
    @media screen and (min-width:769px) and (max-width:1439px) {
      max-width: 80%;
    }
  }
  .tab-wrapper {
  }

  .card-container{
    position:relative;
    padding-left:35px;
    padding-right:35px;
    width: 100%;
    top: -52px;
    /*.card {
      padding: 30px 30px 50px 30px;
      margin-bottom:0;
      border-radius: 0;
      border: none;
      background: white;
    }*/
  }

  .pension_params {
    background-color: #fff;
    text-align: left;
    margin-top: -50px;
  }
  /*#start_year, #end_year {
    width: 60px;
  }*/
  #start_year, #end_year {
    display: inline;
  }
  .form-group {
    margin-bottom: 0.5rem;
  }
  #pension_withdrawal_age {
    float: left;
  }
  .inYearSection {
    float: left;
    height: 100%;
    padding: 0 5px;
    position: relative;
    width: 40%;
    @media screen and (max-width:768px) {
      width: 15%;
    }
    @media screen and (min-width:769px) and (max-width:1439px) {
      width: 20%
    }
    span {
      position: absolute;
      font-weight: normal;
      top: 29%;
    }
  }
  .was-validated .form-control:invalid, .form-control.is-invalid, .form-control.is-valid {
    background-image: none;
    padding-right: initial;
  }
  .form-control.is-valid {
    border-color: #ced4da;
  }
}
</style>
