<template>
  <b-container fluid>
    <slot name="header">
    </slot>
    <b-row class="amount-chart-wrapper-row pt-3">
      <b-col no-gutters>
        <div class="chart-wrapper" style="padding-bottom:100px;" ref="chart-wrapper">
          <bar-chart v-if="loaded"
            :chart-data="chartDatacollection"
            :currency="currency"
            :currencySymbol="currencySymbol"
            :options="chartOptions"
            :chart-id="'bajschart'"
            :width="100"
            :height="400"
            :css-classes="''"
            :plugins="[]"
          >
          </bar-chart>
          <template v-else>
            <b-alert class="mb-0" show>{{ $t(translationPath + 'no_data') }}</b-alert>
          </template>
      </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import BarChart from '@/components/chartjs/BarChart'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'TabAmount',
  props: ['apiData'],
  components: {
    BarChart
  },
  data () {
    return {
      loaded: false,
      chartDatacollection: null,
      chartOptions: null,
      currency: 'kr',
      currencySymbol: 'SEK',
      translationPath: 'reports.simulators.pension.chart.amount.'
    }
  },
  computed: {
    ...mapState('user', ['currentCOA', 'currentUser'])
  },
  methods: {
    fillGraph () {
      const suffix = this.currentCOA.currency ? ' ' + this.currentCOA.currency : ''
      this.chartOptions = {
        defaultFontFamily: 'Nunito',
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              tooltipItem.yLabel = Math.floor(tooltipItem.yLabel)
              return data.datasets[tooltipItem.datasetIndex].label + ': ' + formatNumberToFull(tooltipItem.yLabel).split(' ').join('')
            }
          }
        },
        title: {
          display: false,
          text: 'Custom as Title',
          fontColor: '#231f20'
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontColor: '#231f20',
            fontFamily: 'Nunito'
          }
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: true
            },
            ticks: {
              fontColor: '#231f20',
              fontFamily: 'Nunito'
            }
          }],
          yAxes: [{
            display: true,
            gridLines: {
              display: true
            },
            ticks: {
              fontColor: '#231f20',
              fontFamily: 'Nunito',
              callback: (value, index, values) => {
                return formatNumberToFull(value) + suffix
              }
            }
          }]
        },
        pointDot: true,
        pointDotRadius: 5,
        pointDotStrokeWidth: 1,

        onAnimationComplete: null,
        scaleLineColor: 'rgba(0,0,0,.25)',

        responsive: true,
        maintainAspectRatio: false
      }
      const yearKeys = []
      const datasets = [{
        label: this.$t(this.translationPath + 'label_dataset_amount_needed_to_retire'),
        type: 'line',
        borderColor: '#A5ADBA',
        borderWidth: 2,
        fill: false,
        data: []
      }, {
        label: this.$t(this.translationPath + 'label_dataset_amount'),
        type: 'bar',
        backgroundColor: '#8cccad',
        data: []
      }]
      for (var prop in this.apiData) {
        datasets[1].data.push(this.apiData[prop].amount)
        datasets[0].data.push(this.apiData[prop].amount_needed_to_retire)
        yearKeys.push(this.apiData[prop].year)
      }

      const dataCollection = {
        labels: yearKeys,
        datasets: datasets
      }

      this.chartDatacollection = dataCollection
      this.loaded = true
    }
  },
  watch: {
    apiData: {
      deep: true,
      handler () {
        this.fillGraph()
      }
    }
  },
  mounted () {
    if (this.apiData) {
      this.fillGraph()
    }
  }
}
</script>
