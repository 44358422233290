<template>
  <b-row>
    <b-col
      v-if="slider"
      sm="12"
      :md="sliderMd"
      :xl="sliderXl"
      :order="sliderPosition === 'right' ? 2 : 1"
    >
      <b-form-input class="slider" type="range" :min="min" :max="max" :step="step"
        v-model="val"
        @input="onInput"
      ></b-form-input>
    </b-col>
    <b-col
      sm="12"
      :md="slider ? (12 - sliderMd) : 12"
      :xl="slider ? (12 - sliderXl) : 12"
      :order="sliderPosition === 'right' ? 1 : 2"
      :class="sliderPosition === 'right' ? 'pr-0' : ''"
    >
      <b-form-input
        v-model="val"
        type="number"
        :id="inputId"
        :class="inputClass"
        :placeholder="placeholder"
        @input="onInput"
        @blur="onBlur"
      />
    </b-col>
  </b-row>
</template>

<script>

export default {
  name: 'NumberInput',
  props: {
    value: {
      type: [Number, String]
    },
    precision: {
      type: Number,
      default: 0
    },
    slider: {
      type: Boolean,
      default: false
    },
    step: {
      type: Number,
      default: 1
    },
    inputClass: {
      type: String,
      default: ''
    },
    inputId: {
      type: String,
      default: ''
    },
    sliderPosition: {
      type: String,
      default: 'left'
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 999999999
    },
    sliderXl: {
      type: Number,
      default: 10
    },
    sliderMd: {
      type: Number,
      default: 9
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: Number,
      default: null
    },
    hidePercentageSymbol: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      val: 0
    }
  },
  created () {
    this.val = this.value
  },
  methods: {
    onInput () {
      this.val = parseInt(this.val)
      if (isNaN(this.val)) {
        this.val = 0
      }
      if (this.val <= this.max && this.val >= this.min) {
        this.$emit('input', this.val)
      }
    },
    onBlur () {
      this.val = parseInt(this.val)
      if (isNaN(this.val)) {
        this.val = 0
      }
      if (this.val < this.min) {
        this.val = this.min
      } else if (this.val > this.max) {
        this.val = this.max
      }
      this.$emit('input', this.val)
    }
  },
  watch: {
    value () {
      this.val = this.value
    }
  }
}
</script>
